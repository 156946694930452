import { Typewriter } from 'react-simple-typewriter'
function Banner() {
    return (
        <div className="w-full h-screen relative banner-pattern">
            <div className="w-full h-full flex justify-center items-center">
                <div className="w-[65%] h-auto">
                    <div className="w-full h-auto">
                        <h1 className="text-[7rem] font-onset font-semibold text-hackathon-dark text-center">Join the Coding Festival</h1>

                        <h1 className="text-[26rem] font-onset font-semibold text-hackathon-primary text-center leading-none">
                            <Typewriter
                                words={['Complete', 'Create', "Celebrate!"]}
                                loop={true}
                                cursor
                                cursorStyle='|'
                                typeSpeed={100}
                                deleteSpeed={100}
                                delaySpeed={3000}
                                
                            /></h1>
                    </div>
                </div>
            </div>
            <div className="w-full h-full absolute top-0 left-0">
                <div className="w-full h-full relative">
                    <div className="absolute top-[18%] left-[37%]">
                        <img src={require("../../../assets/images/banner-ele-1.png")} alt="Element" />
                    </div>
                    <div className="absolute top-[19%] left-[58%]">
                        <img src={require("../../../assets/images/banner-ele-2.png")} alt="Element" />
                    </div>
                    <div className="absolute top-[67%] left-[65%]">
                        <img src={require("../../../assets/images/banner-ele-3.png")} alt="Element" />
                    </div>
                    <div className="absolute top-[82%] left-[32%]">
                        <img src={require("../../../assets/images/banner-ele-4.png")} alt="Element" />
                    </div>
                    <div className="absolute top-[54%] left-[0%]">
                        <img src={require("../../../assets/images/banner-ele-5.png")} alt="Element" />
                    </div>
                    <div className="absolute top-[21%] left-[14%]">
                        <img src={require("../../../assets/images/banner-ele-6.png")} alt="Element" />
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Banner;