import { LeftArrowIcon, RightArrowIcon } from "../../svg";

function Testimonials() {
    return (
        <div className="w-full h-auto py-[5%]">
            <div className="w-[77.6%] mx-auto">
                <div className="w-full bg-[#F8F8FF] rounded-[2.8rem] rounded-tl-[35rem] p-[3.2rem]">
                    <div className="w-full flex justify-between gap-[8.5rem]">
                        <div className="w-1/2">
                            <div className="w-[87%] h-full py-[7.8rem] ml-auto flex flex-col justify-between items-end">
                                <div className="w-full">
                                    <h3 className="text-[4.5rem] font-onest font-semibold text-right text-hackathon-primary leading-tight">Mary Agnes</h3>
                                    <p className="text-[2.9rem] font-onest font-medium text-hackathon-dark text-right leading-tight">Developer</p>
                                </div>
                                <div className="w-full">
                                    <p className="text-[3.4rem] font-onest font-medium text-hackathon-dark text-right leading-tight">The fact that our hack [InstaDApp] saw the light of the day is a great signal about the success of the hackathons.</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <div className="w-full h-auto rounded-[2.8rem] relative overflow-hidden">
                                <div className="w-full h-auto relative before:content-[''] before:bg-hackathon-primary before:bg-opacity-10 before:w-full before:h-full before:absolute before:top-0 before:left-0 after:content-[''] after:bg-gradient-to-t after:from-[#000000a9] after:to-[#00000000] after:w-full after:h-full after:absolute after:top-0 after:left-0">
                                    <img src={require("../../../assets/images/testimonials-1.png")} alt="Testimonials" />
                                </div>
                                <div className="w-full p-[1.5rem] h-auto absolute bottom-0 left-0">
                                    <div className="w-full flex justify-end items-center gap-6">
                                        <button type="button" className="w-[6.8rem] h-[6.8rem] bg-hackathon-white rounded-full flex justify-center items-center">
                                            <div className="w-[2.7rem] h-auto">
                                                <LeftArrowIcon />
                                            </div>
                                        </button>
                                        <button type="button" className="w-[6.8rem] h-[6.8rem] bg-hackathon-white rounded-full flex justify-center items-center">
                                        <div className="w-[2.7rem] h-auto">
                                                <RightArrowIcon />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Testimonials;