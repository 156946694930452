import { useState } from "react";
import { OptionAllIcon, OptionBadgeClaimedIcon, OptionCompletedIcon, OptionInProgressIcon, OptionPendingIcon, RightArrowIcon, SearchIcon } from "../../svg";
import { useNavigate } from "react-router-dom";
import HackathonInfo from "../hackathon-info";

function HackathonList() {
    const navigate = useNavigate()
    const [selectedOption, setSelectedOption] = useState<string>("all");
    const options = [
        { id: "all", label: "All", icon: <OptionAllIcon /> },
        { id: "pending", label: "Pending", icon: <OptionPendingIcon /> },
        { id: "inProgress", label: "In Progress", icon: <OptionInProgressIcon /> },
        { id: "completed", label: "Completed", icon: <OptionCompletedIcon /> },
        { id: "badgeClaimed", label: "Badge Claimed", icon: <OptionBadgeClaimedIcon /> },
    ]

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
        console.log("Selected option:", event.target.value);
    };

    const hackathonListItems = [
        { title: "Sui Overflow" },
        { title: "Sui Overflow" },
        { title: "Sui Overflow" },
        { title: "Sui Overflow" },
        { title: "Sui Overflow" },
        { title: "Sui Overflow" },
        { title: "Sui Overflow" },
        { title: "Sui Overflow" },
        { title: "Sui Overflow" },
        { title: "Sui Overflow" },
    ]
    return (
        <div className="w-full h-auto">
            <div className="w-full h-auto bg-[#F9F9F9] rounded-t-[10.5rem] py-[7.7rem]">
                <div className="w-[77.6%] mx-auto">
                    <div className="w-full py-[1%] border-b border-[#EBEBEB] mb-[5rem]">
                        <div className="w-full h-auto flex justify-between items-center mb-[1%]">
                            <div className="w-auto">
                                <div className="w-auto h-auto flex justify-start items-center max-xl:w-full max-xl:mb-[3%] gap-[1.2rem] max-3xl:gap-[0.8rem] relative">
                                    {options.map((option) => (
                                        <div key={option.id}>
                                            <input
                                                type="radio"
                                                id={option.id}
                                                value={option.id}
                                                className="hidden peer"
                                                checked={selectedOption === option.id}
                                                onChange={handleOptionChange}
                                            />
                                            <label
                                                htmlFor={option.id}
                                                className={`w-auto h-auto flex justify-center items-center gap-4 cursor-pointer text-[2rem] px-[1.5rem] py-[1rem] rounded-full leading-none font-onest font-normal border border-hackathon-dark bg-hackathon-white fill-hackathon-dark text-hackathon-dark peer-checked:bg-hackathon-dark peer-checked:text-hackathon-white peer-checked:border-hackathon-dark peer-checked:fill-hackathon-white`}
                                            >
                                                <div className="w-[2rem] h-auto">
                                                    {option.icon}
                                                </div>
                                                {option.label}
                                            </label>

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="w-auto relative">
                                <input
                                    type="text"
                                    className="w-[5.6rem] h-[5.6rem] bg-transparent text-[1.7rem] font-inter placeholder:text-hackathon-dark text-hackathon-dark font-normal py-[0.8rem] px-0 border border-hackathon-dark rounded-full transition-all duration-300 focus:w-[28rem] focus:border-hackathon-dark focus:pl-8 focus:pr-[4.5rem] focus:placeholder:text-hackathon-gray-66 relative search-icon"
                                    placeholder=""
                                />
                                <div className="w-[2rem] h-[2rem] absolute top-[50%] right-[2rem] translate-y-[-50%] pointer-events-none">
                                    <SearchIcon />
                                </div>

                            </div>
                        </div>
                        <p className="text-[1.8rem] font-onest font-light text-hackathon-gray-66">Showing 11 Pending</p>
                    </div>
                    <div className="grid grid-cols-2 gap-[7.6rem]">
                        {hackathonListItems.map((items: any, i: number) => (
                            <div className="w-full h-auto group" onClick={() => navigate("/hackathon/sui-overflow")}>
                                <div className="w-full h-auto relative rounded-[2.8rem] overflow-hidden border-[0.5rem] border-transparent mb-[2.5rem] transition-all duration-300 group-hover:rounded-tl-[16rem] group-hover:border-[#6CEFD9]">
                                    <div className="w-full h-auto">
                                        <img src={require("../../../assets/images/hackathon-list-1.png")} alt="Hackathon Image" />
                                    </div>
                                    <div className="w-full h-full absolute top-0 left-0">
                                        <div className="w-full h-full pt-[1.8rem] pl-[4.2rem] pb-[2.8rem] pr-[1.8rem] card-overlay flex flex-col justify-between">
                                            <div className="w-full text-right z-[2]">
                                                <div className="w-auto h-auto inline-flex justify-center items-center gap-2 bg-[#DCAC01] px-6 py-2 rounded-full">
                                                    <div className="w-[2rem] h-auto fill-hackathon-white">
                                                        <OptionPendingIcon />
                                                    </div>
                                                    <p className="text-[2rem] font-onest font-normal text-hackathon-white leading-tight">Pending</p>
                                                </div>
                                            </div>
                                            <div className="w-full z-[2]">
                                                <h2 className="w-[60%] text-[4rem] font-onest font-semibold text-hackathon-white leading-tight mb-4">Sui Overflow</h2>
                                                <div className="w-full h-auto flex justify-between items-center">
                                                    <div className="w-[70%]">
                                                        <div className="w-full flex justify-start items-center gap-[0.9rem]">
                                                            <div className="w-auto inline-block text-[1.5rem] font-normal font-onest text-hackathon-white border border-hackathon-white px-[2.5rem] py-[0.6rem] rounded-full">
                                                                Azure Security
                                                            </div>
                                                            <div className="w-auto inline-block text-[1.5rem] font-normal font-onest text-hackathon-white border border-hackathon-white px-[2.5rem] py-[0.6rem] rounded-full">
                                                                Microsoft Dynamics
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-[30%] flex justify-end">
                                                        <button type="button" className="text-[1.8rem] font-onest font-normal bg-hackathon-white text-hackathon-dark px-[2.2rem] py-[1.2rem] leading-none rounded-full flex justify-center items-center gap-[0.2rem] transition-all duration-100 invisible group-hover:visible ">Participate <div className="w-[1.8rem] h-auto fill-hackathon-dark"><RightArrowIcon /></div></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full h-auto">

                                    <HackathonInfo />
                                    <div className="w-full mt-[3rem]">
                                        <p className="text-[2.4rem] font-dmsans font-normal text-hackathon-gray-66 leading-snug">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="w-full flex justify-center items-center my-[3%]">
                        <button type="button" className="w-[30%] text-[2.2rem] font-dmsans font-medium bg-hackathon-dark-1d text-hackathon-white py-[2rem] leading-none rounded-full">Show More</button>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default HackathonList;