import gsap from "gsap";
import { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { LeftArrowIcon, RightArrowIcon } from "../../svg";
gsap.registerPlugin(ScrollTrigger);

function OngoingHackathon(){
    let galleryRef = useRef<any>(null);
    useEffect(() => {
      setTimeout(() => {
        const sections = gsap.utils.toArray(".gallery-card");
        console.log(galleryRef.current, ScrollTrigger);
        
        gsap.to(sections, {
          xPercent: -100 * (sections.length - 1),
          ease: "none",
          duration: 6,
          scrollTrigger: {
            trigger: galleryRef.current,
            start: 'center center',
            pin: "#wrapper",
            scrub: true,
            markers: false,
            end: `+=${galleryRef.current.offsetWidth}`,
            invalidateOnRefresh: true
          }
        });
  
        ScrollTrigger.refresh();
  
        console.log(galleryRef, sections);
      });
    });
   
    return(
        <div className="w-full h-auto py-[8%] grid place-content-start" id="wrapper" >
            <div className="w-full h-full flex  shrink-0" ref={galleryRef}>
                <div className="w-[54.5rem] z-[1] mx-[4rem] ml-[5%] gallery-card">
                    <div className="w-full h-full flex flex-col justify-between items-start">
                        <h3 className="text-[8rem] font-onest font-semibold text-hackathon-dark text-left leading-tight">Ongoing <span className="text-hackathon-primary">Hackathons</span></h3>
                        <button type="button" className="text-[2.2rem] font-dmsans font-medium bg-none border border-hackathon-dark-1d text-hackathon-dark-1d px-[4.2rem] py-[2rem] leading-none rounded-full">See All</button>
                    </div>
                </div>
                <div className="w-[54.5rem] z-[1] mx-[4rem] gallery-card group">
                    <div className="w-full h-auto rounded-[2.8rem] border-[0.4rem] border-transparent transition-all duration-300 group-hover:rounded-tl-[18.5rem] group-hover:border-[#89E5D6] overflow-hidden relative">
                        <div className="w-full h-auto relative card-overlay">
                        <img src={require("../../../assets/images/ongoing-hackathon-1.png")} alt="OnGoing Hackathon" />
                        </div>
                        <div className="w-full h-full absolute top-0 left-0">
                            <div className="w-full h-full px-[4rem] py-[3rem] flex flex-col justify-end items-start">
                                <h3 className="text-[5rem] font-onest font-medium text-hackathon-white leading-tight">Code Clash’24</h3>
                                <div className="w-full flex justify-start items-center gap-[1.2rem] mb-[1rem]">
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-white leading-none">Microsoft Dynamics</p>
                                    <span className="text-[4rem] font-onest font-normal text-hackathon-white leading-none relative bottom-4">.</span>
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-white leading-none">June 03, 2024</p>
                                </div>
                                <button type="button" className="w-[6.8rem] h-[6.8rem] transition-all duration-300 group-hover:w-full bg-hackathon-white rounded-full flex justify-center items-center gap-0 group-hover:gap-4">
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-dark leading-tight w-0 opacity-0 transition-opacity duration-500 invisible group-hover:opacity-100 group-hover:w-auto group-hover:visible">Participate</p>
                                    <div className="w-[2.7rem] h-auto">
                                        <RightArrowIcon />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[54.5rem] z-[1] mx-[4rem] gallery-card group">
                    <div className="w-full h-auto rounded-[2.8rem] border-[0.4rem] border-transparent transition-all duration-300 group-hover:rounded-tl-[18.5rem] group-hover:border-[#89E5D6] overflow-hidden relative">
                        <div className="w-full h-auto relative card-overlay">
                        <img src={require("../../../assets/images/ongoing-hackathon-2.png")} alt="OnGoing Hackathon" />
                        </div>
                        <div className="w-full h-full absolute top-0 left-0">
                            <div className="w-full h-full px-[4rem] py-[3rem] flex flex-col justify-end items-start">
                                <h3 className="text-[5rem] font-onest font-medium text-hackathon-white leading-tight">Story Protocol v1.0 Buildathon</h3>
                                <div className="w-full flex justify-start items-center gap-[1.2rem] mb-[1rem]">
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-white leading-none">Azure Security</p>
                                    <span className="text-[4rem] font-onest font-normal text-hackathon-white leading-none relative bottom-4">.</span>
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-white leading-none">June 10, 2024</p>
                                </div>
                                <button type="button" className="w-[6.8rem] h-[6.8rem] transition-all duration-300 group-hover:w-full bg-hackathon-white rounded-full flex justify-center items-center gap-0 group-hover:gap-4">
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-dark leading-tight w-0 opacity-0 transition-opacity duration-500 invisible group-hover:opacity-100 group-hover:w-auto group-hover:visible">Participate</p>
                                    <div className="w-[2.7rem] h-auto">
                                        <RightArrowIcon />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[54.5rem] z-[1] mx-[4rem] gallery-card group">
                    <div className="w-full h-auto rounded-[2.8rem] border-[0.4rem] border-transparent transition-all duration-300 group-hover:rounded-tl-[18.5rem] group-hover:border-[#89E5D6] overflow-hidden relative">
                        <div className="w-full h-auto relative card-overlay">
                        <img src={require("../../../assets/images/ongoing-hackathon-3.png")} alt="OnGoing Hackathon" />
                        </div>
                        <div className="w-full h-full absolute top-0 left-0">
                            <div className="w-full h-full px-[4rem] py-[3rem] flex flex-col justify-end items-start">
                                <h3 className="text-[5rem] font-onest font-medium text-hackathon-white leading-tight">HackPrix</h3>
                                <div className="w-full flex justify-start items-center gap-[1.2rem] mb-[1rem]">
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-white leading-none">Azure Security</p>
                                    <span className="text-[4rem] font-onest font-normal text-hackathon-white leading-none relative bottom-4">.</span>
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-white leading-none">June 10, 2024</p>
                                </div>
                                <button type="button" className="w-[6.8rem] h-[6.8rem] transition-all duration-300 group-hover:w-full bg-hackathon-white rounded-full flex justify-center items-center gap-0 group-hover:gap-4">
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-dark leading-tight w-0 opacity-0 transition-opacity duration-500 invisible group-hover:opacity-100 group-hover:w-auto group-hover:visible">Participate</p>
                                    <div className="w-[2.7rem] h-auto">
                                        <RightArrowIcon />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[54.5rem] z-[1] mx-[4rem] gallery-card group">
                    <div className="w-full h-auto rounded-[2.8rem] border-[0.4rem] border-transparent transition-all duration-300 group-hover:rounded-tl-[18.5rem] group-hover:border-[#89E5D6] overflow-hidden relative">
                        <div className="w-full h-auto relative card-overlay">
                        <img src={require("../../../assets/images/ongoing-hackathon-4.png")} alt="OnGoing Hackathon" />
                        </div>
                        <div className="w-full h-full absolute top-0 left-0">
                            <div className="w-full h-full px-[4rem] py-[3rem] flex flex-col justify-end items-start">
                                <h3 className="text-[5rem] font-onest font-medium text-hackathon-white leading-tight">Hack with MLSA-IGNOU</h3>
                                <div className="w-full flex justify-start items-center gap-[1.2rem] mb-[1rem]">
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-white leading-none">Azure Security</p>
                                    <span className="text-[4rem] font-onest font-normal text-hackathon-white leading-none relative bottom-4">.</span>
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-white leading-none">June 10, 2024</p>
                                </div>
                                <button type="button" className="w-[6.8rem] h-[6.8rem] transition-all duration-300 group-hover:w-full bg-hackathon-white rounded-full flex justify-center items-center gap-0 group-hover:gap-4">
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-dark leading-tight w-0 opacity-0 transition-opacity duration-500 invisible group-hover:opacity-100 group-hover:w-auto group-hover:visible">Participate</p>
                                    <div className="w-[2.7rem] h-auto">
                                        <RightArrowIcon />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[54.5rem] z-[1] mx-[4rem] gallery-card group">
                    <div className="w-full h-auto rounded-[2.8rem] border-[0.4rem] border-transparent transition-all duration-300 group-hover:rounded-tl-[18.5rem] group-hover:border-[#89E5D6] overflow-hidden relative">
                        <div className="w-full h-auto relative card-overlay">
                        <img src={require("../../../assets/images/ongoing-hackathon-5.png")} alt="OnGoing Hackathon" />
                        </div>
                        <div className="w-full h-full absolute top-0 left-0">
                            <div className="w-full h-full px-[4rem] py-[3rem] flex flex-col justify-end items-start">
                                <h3 className="text-[5rem] font-onest font-medium text-hackathon-white leading-tight">VSOC</h3>
                                <div className="w-full flex justify-start items-center gap-[1.2rem] mb-[1rem]">
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-white leading-none">Azure Security</p>
                                    <span className="text-[4rem] font-onest font-normal text-hackathon-white leading-none relative bottom-4">.</span>
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-white leading-none">June 10, 2024</p>
                                </div>
                                <button type="button" className="w-[6.8rem] h-[6.8rem] transition-all duration-300 group-hover:w-full bg-hackathon-white rounded-full flex justify-center items-center gap-0 group-hover:gap-4">
                                    <p className="text-[2.2rem] font-onest font-normal text-hackathon-dark leading-tight w-0 opacity-0 transition-opacity duration-500 invisible group-hover:opacity-100 group-hover:w-auto group-hover:visible">Participate</p>
                                    <div className="w-[2.7rem] h-auto">
                                        <RightArrowIcon />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OngoingHackathon;


