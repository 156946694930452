import './App.css';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from './pages/layout';
import NotFound from './pages/not-found';
import Home from './pages/home';
import Hackathon from './pages/hackathon';
import HackathonDetailWrapper from './pages/hackathon-detail-wrapper';

function App() {

  const router = createBrowserRouter([
    {
      path: "",
      element: <Layout />,
      errorElement: <NotFound />,
      children: [
        {
          path: "",
          element: <Home />
        },
        {
          path: "/hackathon",
          element: <Hackathon />,
          children: [
            {
              path: ":id",
              element: <HackathonDetailWrapper />
            }
          ]
        },
      ]
    }]);

  return (
    <RouterProvider router={router}></RouterProvider>
  );
}

export default App;
