function GateWay(){
    return(
        <div className="w-full h-auto bg-hackathon-primary">
            <div className="w-full py-[5%] flex justify-center items-center gateway-pattern">
                <div className="w-[55%] h-auto text-center">
                    <h3 className="text-[6rem] font-onest text-hackathon-white font-semibold">"Your gateway to top hackathons"</h3>
                    <p className="text-[2.8rem] font-dmsans text-hackathon-white font-normal mb-[8rem]">"Applying to hackathons is as simple as a click of a button. We save all the required info so that you don’t have to fill it out repeatedly."</p>
                    <button type="button" className="text-[2.2rem] font-dmsans font-medium bg-none border border-hackathon-white text-hackathon-white px-[4.2rem] py-[2rem] leading-none rounded-full">Browse all Hackathons</button>
                </div>
            </div>
        </div>
    )
}
export default GateWay;