import Footer from "../components/global-layouts/footer";
import Amazed from "../components/section/amazed";
import Banner from "../components/section/banner";
import CelebrityCoders from "../components/section/celebrity-coders";
import GateWay from "../components/section/gateway";
import OngoingHackathon from "../components/section/ongoing-hackathon";
import Testimonials from "../components/section/testimonials";

function Home(){
    return(
        <div className="w-full h-auto">
            <Banner />
            <Amazed />
            <OngoingHackathon />
            <Testimonials />
            <CelebrityCoders />
            <GateWay />
        </div>
    )
}
export default Home;