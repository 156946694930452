import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/global-layouts/header";
import Footer from "../components/global-layouts/footer";
import { useEffect, useState } from "react";
import Login from "../components/section/login";

function Layout(){
    const location = useLocation();
    const footerOnlyRoutes = ["/", "/contact"];
    const isFooterOnly = footerOnlyRoutes.includes(location.pathname);

    const [loginModal, setLoginModal] = useState(false);
    const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return(
        <div className="w-full h-auto relative">
            <Header handleOpenLogin={() => setLoginModal(true)} />
            <Outlet />
            <Footer footerOnly={isFooterOnly} />
            {loginModal && <Login handleClose={() => setLoginModal(false)} />}
        </div>
    )
}
export default Layout;