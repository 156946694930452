import { Link } from "react-router-dom";
import { SpinUpButton } from "../../svg";
import Challenges from "../challenges";

function HackathonDetail() {
    return (
        <div className="w-full h-auto">
            <div className="w-full h-auto bg-[#F9F9F9] rounded-t-[10.5rem] py-[2rem]">
                <div className="w-[77.6%] mx-auto">
                    <div className="w-full flex justify-between items-start mb-[5%]">
                        <div className="w-2/5">
                            <div className="w-full h-auto">
                                <img src={require("../../../assets/images/hackathon-detail.png")} alt="Hackathon Detail" />
                            </div>
                        </div>
                        <div className="w-3/5">
                            <div className="w-full h-auto mt-[11%]">
                                <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-10 leading-relaxed">A hackathon is an event where programmers, developers, and designers come together to collaborate on coding projects within a set timeframe, typically ranging from a few hours to a couple of days. Participants work either individually or in teams to create software solutions, applications, or prototypes from scratch or build upon existing ideas.</p>
                                <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-10 leading-relaxed">These events are often centered around a specific theme or challenge, which guides participants in their project development. Themes can range from solving real-world problems, creating innovative products, exploring emerging technologies, to addressing social or environmental issues. During a coding hackathon, participants leverage their coding skills, creativity, and teamwork to develop functional prototypes or minimum viable products (MVPs). They may use various programming languages, frameworks, APIs, and tools depending on the requirements of their projects.</p>
                                <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-10 leading-relaxed">Hackathons provide a unique opportunity for participants to showcase their technical abilities, learn new technologies, collaborate with peers, and potentially turn their ideas into viable products or solutions. They also foster a sense of community within the coding and tech industry, promoting innovation and skill development. Additionally, many hackathons offer prizes, mentorship opportunities, and networking prospects for participants.</p>
                                <div className="w-full mt-14 pb-16 mb-16  border-b border-dashed border-[##D7D7D7]">
                                    <h5 className="text-[3.2rem] font-onest font-semibold text-hackathon-dark mb-6">Refresh your Knowledge</h5>
                                    <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-6">Explore the <Link to="" className="text-hackathon-primary underline">Reading Materials</Link> of Sui Overflow Hackathons.</p>
                                    <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-6">Explore the <Link to="" className="text-hackathon-primary underline">Terms & Conditions</Link> of Sui Overflow Hackathons.</p>
                                    <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-6">Please review the <Link to="" className="text-hackathon-primary underline">Rules</Link> governing participation in this Hackathons.</p>
                                </div>
                                <div className="w-full flex justify-center items-center gap-[3rem]">
                                    <div className="w-[12rem] h-auto">
                                        <SpinUpButton />
                                    </div>
                                    <div className="flex-1 ">
                                        <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 leading-relaxed">Spinning this up will prepare your infrastructure for the Hackathon. Submit PoEs for all challenges by the end date to complete your Hackathon. The infrastructure will be shut down after the end date."</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Challenges />
                </div>
            </div>
        </div>
    )
}
export default HackathonDetail;