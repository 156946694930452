import PageHeader from "../components/global-layouts/page-header";
import HackathonDetail from "../components/section/hackathon-detail";

function HackathonDetailWrapper(){
    const breadcrumbs = [
        { label: "Hackathon", link: "/hackathon" },
        { label: "Sui Overflow", link: undefined },
      ];
    
    return(
        <>
        <PageHeader pageTitle="Sui Overflow" breadcrumbs={breadcrumbs} hackathonDetail={true} />
        <HackathonDetail />
        </>
    )
}
export default HackathonDetailWrapper;