import { Link } from "react-router-dom";
import { HackathonFooterText, ToTopicon } from "../../svg";

interface FooterProps {
    footerOnly: boolean;
}

function Footer({ footerOnly }: FooterProps) {
    const communityLinks = [
        { label: "Host a Hackathon", link: "/" },
        { label: "Explore Hackathons", link: "/" },
        { label: "Code of Conduct", link: "/" }
    ]
    const companyLinks = [
        { label: "About", link: "/" },
        { label: "Privacy", link: "/" },
        { label: "Terms", link: "/" }
    ]
    const supportLinks = [
        { label: "Help", link: "/" },
        { label: "Contact Us", link: "/" },
    ]
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <div className={`w-full h-auto ${footerOnly ? 'bg-hackathon-primary gateway-pattern-2' : ''} `}>
            <div className="w-full h-full bg-hackathon-white rounded-t-[10.2rem] pt-[7%]">
                <div className="w-[77.6%] mx-auto footer-pattern">
                    <div className="grid grid-cols-6 gap-8">
                        <div className="w-full col-span-3">
                            <div className="w-full h-auto">
                                <h5 className="text-[5rem] font-onest font-semibold text-hackathon-dark leading-none mb-[4.6rem]">"Our admiration for <br /> software extends to <br /> its creators."</h5>
                            </div>
                        </div>
                        <div className="w-full">
                            <h6 className="text-[2.6rem] font-onest font-semibold text-hackathon-dark mb-4">Community</h6>
                            <ul>
                                {communityLinks.map((communityLink) => (
                                    <li className="w-auto text-[2rem] font-dmsans font-normal text-hackathon-gray-66 mb-2">
                                        <Link to={communityLink.link}>{communityLink.label}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="w-full">
                            <h6 className="text-[2.6rem] font-onest font-semibold text-hackathon-dark mb-4">Company</h6>
                            <ul>
                                {companyLinks.map((communityLink) => (
                                    <li className="w-auto text-[2rem] font-dmsans font-normal text-hackathon-gray-66 mb-2">
                                        <Link to={communityLink.link}>{communityLink.label}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="w-full">
                            <h6 className="text-[2.6rem] font-onest font-semibold text-hackathon-dark mb-4">Support</h6>
                            <ul>
                                {supportLinks.map((communityLink) => (
                                    <li className="w-auto text-[2rem] font-dmsans font-normal text-hackathon-gray-66 mb-2">
                                        <Link to={communityLink.link}>{communityLink.label}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                    </div>
                    <div className="w-full h-auto flex justify-end items-center my-[5%]">
                        <button type="button" className="text-[2.2rem] font-dmsans font-medium bg-none border border-hackathon-dark text-hackathon-dark px-[2.5rem] py-[1rem] leading-none rounded-full flex justify-content-center items-center gap-2" onClick={scrollToTop} ><div className="w-[1.8rem] h-auto"><ToTopicon /></div>Top</button>
                    </div>
                    <div className="w-full">
                        <HackathonFooterText />
                    </div>
                    <div className="w-full text-center pb-[2%]">
                        <p className="text-[2.2rem] font-dmsans font-normal text-hackathon-gray-66">2024 Hackathon. All rights reserved</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;