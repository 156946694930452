import gsap from "gsap";
import { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CelebrityCodersText, LeftArrowIcon, RightArrowIcon } from "../../svg";

gsap.registerPlugin(ScrollTrigger);
function CelebrityCoders(){
    let coderRef = useRef<any>(null);
    useEffect(() => {
      setTimeout(() => {
        const sections = gsap.utils.toArray(".coder-card");
        console.log(coderRef.current, ScrollTrigger);
        
        gsap.to(sections, {
          xPercent: -100 * (sections.length - 1),
          ease: "none",
          duration: 6,
          scrollTrigger: {
            trigger: coderRef.current,
            start: 'center center',
            pin: "#celebrityCodersWrapper",
            scrub: true,
            markers: false,
            end: `+=${coderRef.current.offsetWidth}`,
            invalidateOnRefresh: true
          }
        });
  
        ScrollTrigger.refresh();
  
        console.log(coderRef, sections);
      });
    });
    return(
        <div className="w-full h-auto py-[5%] relative grid place-content-start" id="celebrityCodersWrapper" >
            <div className="w-1/2 absolute top-[50%] left-[11.2%] translate-y-[-50%]">
                <CelebrityCodersText />
            </div>
             <div className="w-full h-full flex shrink-0 " ref={coderRef}>
                    <div className="w-[41rem] h-auto z-[1] mx-[6rem] coder-card "></div>
                    <div className="w-[41rem] h-auto z-[1] mx-[6rem] coder-card "></div>
                    <div className="w-[41rem] h-[49.5rem] z-[1] mx-[6rem] coder-card group">
                        <div className="w-full h-auto rounded-[2rem] rounded-tl-[14.4rem] bg-[#F3F3FF] mb-8 px-[8%]">
                            <div className="w-full h-auto grayscale group-hover:grayscale-0">
                            <img src={require("../../../assets/images/celebrity-coder-1.png")} alt="Celebrity Coder" />
                            </div>
                        </div>
                        <div className="w-full flex justify-end items-center gap-4">
                        <p className="text-[4.5rem] font-onest font-semibold text-hackathon-dark group-hover:text-hackathon-primary">John Smith </p>
                        <div className="w-0 h-auto opacity-0 transition-opacity duration-700 group-hover:w-[5rem] group-hover:opacity-100 fill-hackathon-primary">
                           <RightArrowIcon />
                        </div>
                        </div>
                    </div>
                    <div className="w-[41rem] h-[49.5rem] z-[1] mx-[6rem] coder-card group">
                        <div className="w-full h-auto rounded-[2rem] rounded-tl-[14.4rem] bg-[#F3F3FF] mb-8 px-[8%]">
                            <div className="w-full h-auto grayscale group-hover:grayscale-0">
                            <img src={require("../../../assets/images/celebrity-coder-2.png")} alt="Celebrity Coder" />
                            </div>
                        </div>
                        <div className="w-full flex justify-end items-center gap-4">
                        <p className="text-[4.5rem] font-onest font-semibold text-hackathon-dark group-hover:text-hackathon-primary">Jenitta </p>
                        <div className="w-0 h-auto opacity-0 transition-opacity duration-700 group-hover:w-[5rem] group-hover:opacity-100 fill-hackathon-primary">
                           <RightArrowIcon />
                        </div>
                        </div>
                    </div>
                    <div className="w-[41rem] h-[49.5rem] z-[1] mx-[6rem] coder-card group">
                        <div className="w-full h-auto rounded-[2rem] rounded-tl-[14.4rem] bg-[#F3F3FF] mb-8 px-[8%]">
                            <div className="w-full h-auto grayscale group-hover:grayscale-0">
                            <img src={require("../../../assets/images/celebrity-coder-1.png")} alt="Celebrity Coder" />
                            </div>
                        </div>
                        <div className="w-full flex justify-end items-center gap-4">
                        <p className="text-[4.5rem] font-onest font-semibold text-hackathon-dark group-hover:text-hackathon-primary">John Smith </p>
                        <div className="w-0 h-auto opacity-0 transition-opacity duration-700 group-hover:w-[5rem] group-hover:opacity-100 fill-hackathon-primary">
                           <RightArrowIcon />
                        </div>
                        </div>
                    </div>
                    <div className="w-[41rem] h-[49.5rem] z-[1] mx-[6rem] coder-card group">
                        <div className="w-full h-auto rounded-[2rem] rounded-tl-[14.4rem] bg-[#F3F3FF] mb-8 px-[8%]">
                            <div className="w-full h-auto grayscale group-hover:grayscale-0">
                            <img src={require("../../../assets/images/celebrity-coder-2.png")} alt="Celebrity Coder" />
                            </div>
                        </div>
                        <div className="w-full flex justify-end items-center gap-4">
                        <p className="text-[4.5rem] font-onest font-semibold text-hackathon-dark group-hover:text-hackathon-primary">Jenitta </p>
                        <div className="w-0 h-auto opacity-0 transition-opacity duration-700 group-hover:w-[5rem] group-hover:opacity-100 fill-hackathon-primary">
                           <RightArrowIcon />
                        </div>
                        </div>
                    </div>
                    <div className="w-[41rem] h-[49.5rem] z-[1] mx-[6rem] coder-card group">
                        <div className="w-full h-auto rounded-[2rem] rounded-tl-[14.4rem] bg-[#F3F3FF] mb-8 px-[8%]">
                            <div className="w-full h-auto grayscale group-hover:grayscale-0">
                            <img src={require("../../../assets/images/celebrity-coder-1.png")} alt="Celebrity Coder" />
                            </div>
                        </div>
                        <div className="w-full flex justify-end items-center gap-4">
                        <p className="text-[4.5rem] font-onest font-semibold text-hackathon-dark group-hover:text-hackathon-primary">John Smith </p>
                        <div className="w-0 h-auto opacity-0 transition-opacity duration-700 group-hover:w-[5rem] group-hover:opacity-100 fill-hackathon-primary">
                           <RightArrowIcon />
                        </div>
                        </div>
                    </div>
                    <div className="w-[41rem] h-[49.5rem] z-[1] mx-[6rem] coder-card group">
                        <div className="w-full h-auto rounded-[2rem] rounded-tl-[14.4rem] bg-[#F3F3FF] mb-8 px-[8%]">
                            <div className="w-full h-auto grayscale group-hover:grayscale-0">
                            <img src={require("../../../assets/images/celebrity-coder-2.png")} alt="Celebrity Coder" />
                            </div>
                        </div>
                        <div className="w-full flex justify-end items-center gap-4">
                        <p className="text-[4.5rem] font-onest font-semibold text-hackathon-dark group-hover:text-hackathon-primary">Jenitta </p>
                        <div className="w-0 h-auto opacity-0 transition-opacity duration-700 group-hover:w-[5rem] group-hover:opacity-100 fill-hackathon-primary">
                           <RightArrowIcon />
                        </div>
                        </div>
                    </div>
                    
             </div>
        </div>
    )
}
export default CelebrityCoders;