import { NavLink } from "react-router-dom";
import HackathonInfo from "../../section/hackathon-info";

interface Breadcrumb {
    label: string;
    link?: any;
}
interface PageHeaderProps {
    pageTitle: string;
    breadcrumbs?: Breadcrumb[];
    hackathonDetail?: boolean;
    hackathonCount?: number;
}
function PageHeader({ pageTitle, breadcrumbs, hackathonDetail, hackathonCount }: PageHeaderProps) {
    return (
        <div className="w-full h-auto pageheader-pattern pt-[10%] py-[2%]">
            <div className="w-[77.6%] mx-auto">
                {breadcrumbs && breadcrumbs.length > 0 && (
                    <div className="w-full mb-6">
                        {breadcrumbs.map((breadcrumb: any, index: any) => (
                            <span
                                key={index}
                                className="text-[2.2rem] font-dmsans font-medium text-hackathon-primary relative mr-8 after:content-['>'] after:text-[2.2rem] after:absolute after:top-[50%] after:translate-y-[-50%] after:right-[-13%] after:text-hackathon-primary last:text-hackathon-dark last:after:hidden"
                            >
                                {breadcrumb.link ? (
                                    <NavLink key={index} to={breadcrumb.link}>
                                        {breadcrumb.label}
                                    </NavLink>
                                ) : (
                                    breadcrumb.label
                                )}
                            </span>
                        ))}
                    </div>
                )}
                <div className="w-full">
                    <h2 className="text-[10rem] font-onest font-semibold text-hackathon-dark relative inline-block leading-none">{pageTitle} {hackathonCount && <span className="absolute top-[-2%] right-[-5%] text-[2.2rem] font-onest font-normal border border-hackathon-dark rounded-full p-2">{hackathonCount}</span>}</h2>
                </div>
                {hackathonDetail && <> <div className="w-full my-[1%]">
                    <div className="w-full flex justify-start items-center gap-[0.9rem]">
                        <div className="w-auto inline-block text-[1.6rem] font-onest font-normal text-hackathon-dark bg-[#F6F6F6] leading-none px-[2.5rem] py-[0.8rem] rounded-full">
                            Azure Security
                        </div>
                        <div className="w-auto inline-block text-[1.6rem] font-onest font-normal text-hackathon-dark bg-[#F6F6F6] leading-none px-[2.5rem] py-[0.8rem] rounded-full">
                            Microsoft Dynamics
                        </div>
                    </div>
                </div>
                    <div className="w-full flex justify-between items-end">
                        <HackathonInfo />
                        <button type="button" className="text-[2.2rem] font-dmsans font-medium bg-hackathon-dark-1d text-hackathon-white px-[4.2rem] py-[2rem] leading-none rounded-full">Participate</button>
                    </div></>}
            </div>
        </div>
    )
}
export default PageHeader;