import { ReactNode, useEffect, useRef } from "react";
interface ModalWrapperProps {
  onClose?: () => void;
  children: ReactNode;
}
function ModalWrapper({
  children,
  onClose,
}: ModalWrapperProps) {
  const modalRef = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
        console.log(modalRef.current , event.target);
        
      if (modalRef.current && modalRef.current == event.target) {
        console.log("close");
        
        onClose?.();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full h-screen fixed top-0 left-0 bottom-0 right-0 z-[40] bg-[#00000033]">
      <div ref={modalRef} className="w-full h-full">
        {children}
      </div>
    </div>
  );
}
export default ModalWrapper;
