import { Link, NavLink, useLocation } from "react-router-dom";
import { HackathonLogo } from "../../svg";
import { useEffect, useState } from "react";

interface HeaderProps{
    handleOpenLogin:() => void;
}

function Header({handleOpenLogin}:HeaderProps){
    const location = useLocation();
    const defaultMenuRoutes = ["/"];
    const isDefaultMenu = defaultMenuRoutes.includes(location.pathname);

    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const menuLinks= [
        {label: "About", link: "/"},
        {label: "Hackathon", link: "/hackathon"},
        {label: "Blogs", link: "/"},
    ]

    const menuActive = (isActive:any) => {
      if(isDefaultMenu){
       return "text-[2.2rem] font-dmsans font-medium text-hackathon-dark"
      }else if(!isDefaultMenu && isActive ){
        return "text-[2.2rem] font-dmsans font-medium text-hackathon-dark relative before:content-[''] before:w-[8px] before:h-[8px] before:rounded-full before:bg-hackathon-dark before:absolute before:top-[50%] before:translate-y-[-50%] before:left-[-1.3rem]"
    }else{
        return "text-[2.2rem] font-dmsans font-medium text-hackathon-gray-66"
      }
    }
    return(
        <div className={`w-full h-auto fixed top-0 left-0 py-[3rem] z-30 transition-colors duration-300 ${scrolled ? "bg-white shadow-sm" : "bg-transparent"}`}>
            <div className="w-[77.6%] mx-auto">
                <div className="w-full flex justify-between items-center">
                    <div className="w-[38%] h-auto">
                        <div className="w-[19rem] h-auto">
                            <Link to={"/"}>
                                <HackathonLogo />
                            </Link>
                        </div>
                    </div>
                    <div className="w-[62%] h-auto">
                        <div className="w-full flex justify-between items-center">
                            <div className="w-auto">
                                <ul className="w-full flex justify-start items-center gap-[30%]">
                                    {menuLinks.map((menuLink, i) =>(
                                        <li className="w-auto">
                                            <NavLink className={({ isActive }) => menuActive(isActive) } to={menuLink.link}>
                                                {menuLink.label}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="w-auto">
                                <div className="w-full h-auto flex justify-end items-center gap-4">
                                    <button type="button" className="text-[2.2rem] font-dmsans font-medium bg-hackathon-dark-1d text-hackathon-white px-[4.2rem] py-[2rem] leading-none rounded-full">Host a Hackathon</button>
                                    <button type="button" className="text-[2.2rem] font-dmsans font-medium bg-none border border-hackathon-dark-1d text-hackathon-dark-1d px-[4.2rem] py-[2rem] leading-none rounded-full" onClick={handleOpenLogin}>Sign In</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header;


// isActive && isDefaultMenu ? `text-[2.2rem] font-dmsans font-medium ${!isDefaultMenu ? 'text-hackathon-dark' : 'text-hackathon-gray-66'} ` : `text-[2.2rem] font-dmsans font-medium ${isDefaultMenu ? 'text-hackathon-dark' : 'text-hackathon-gray-66'} `