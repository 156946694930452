import { DragEvent, useRef, useState } from "react";

function Challenges() {
    const [selectedOption, setSelectedOption] = useState<string>("suiOverflow");
    const dropZoneRef = useRef<HTMLDivElement>(null);
    const uploadFiles = useRef<HTMLInputElement>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const options = [
        { id: "suiOverflow", label: "Sui Overflow" },
        { id: "challenges2", label: "Challenges 2" },
        { id: "challenges3", label: "Challenges 3" },
    ]

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
        console.log("Selected option:", event.target.value);
    };

    const handleDragEnter = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const files = e.dataTransfer.files;
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            console.log(files);

        }
    };
    const handleFileInputChange = (): void => {
        if (
            uploadFiles.current &&
            uploadFiles.current.files &&
            uploadFiles.current.files.length > 0
        ) {
            console.log(uploadFiles.current.files);
        }
    };

    return (
        <div className="w-full h-auto">
            <div className="w-full h-auto border border-[#CAC9C9] rounded-[2.8rem] px-[7.5rem] py-[5rem] mb-[6rem]">
                <div className="w-full">
                    <h1 className="text-[5.4rem] font-onest font-semibold text-hackathon-dark leading-tight mb-12">Challenges</h1>
                    <div className="w-auto h-[5rem] flex justify-start items-center gap-[1.2rem] relative mb-[4.8rem]">
                        {options.map((option) => (
                            <div key={option.id}>
                                <input
                                    type="radio"
                                    id={option.id}
                                    value={option.id}
                                    className="hidden peer"
                                    checked={selectedOption === option.id}
                                    onChange={handleOptionChange}
                                />
                                <label
                                    htmlFor={option.id}
                                    className={`w-auto h-auto cursor-pointer text-[2rem] px-[1.5rem] py-[1rem] rounded-full leading-none font-onest font-normal border border-hackathon-dark bg-hackathon-white fill-hackathon-dark text-hackathon-dark peer-checked:bg-hackathon-dark peer-checked:text-hackathon-white peer-checked:border-hackathon-dark peer-checked:fill-hackathon-white`}
                                >
                                    {option.label}
                                </label>

                            </div>
                        ))}
                    </div>
                    <div className="w-full h-auto">
                        {selectedOption === "suiOverflow" && (
                            <div className="w-full">
                                <h2 className="text-[3.6rem] font-onest font-semibold text-hackathon-dark-1d mb-2">Sui Overflow</h2>
                                <div className="w-full pb-8 border-b border-dashed border-[#D7D7D7] mb-[4rem]">
                                    <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-10 leading-relaxed">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation </p>
                                    <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-10 leading-relaxed">ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                                </div>
                                <div className="w-full flex justify-start items-center gap-[1.8rem]">
                                    <input type="checkbox" name="completed" id="completed" className="w-12 h-12 accent-hackathon-primary" />
                                    <label htmlFor="completed" className="text-[2.4rem] font-dmsans font-normal text-hackathon-dark">I have completed this Challenge </label>
                                </div>
                            </div>
                        )}
                        {selectedOption === "challenges2" && (
                            <div className="w-full">
                                <h2 className="text-[3.6rem] font-onest font-semibold text-hackathon-dark-1d mb-2">Challenges 2</h2>
                                <div className="w-full pb-8 border-b border-dashed border-[#D7D7D7] mb-[4rem]">
                                    <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-10 leading-relaxed">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation </p>
                                    <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-10 leading-relaxed">ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                                </div>
                                <div className="w-full flex justify-start items-center gap-[1.8rem]">
                                    <input type="checkbox" name="completed" id="completed" className="w-12 h-12 accent-hackathon-primary" />
                                    <label htmlFor="completed" className="text-[2.4rem] font-dmsans font-normal text-hackathon-dark">I have completed this Challenge </label>
                                </div>
                            </div>
                        )}
                        {selectedOption === "challenges3" && (
                            <div className="w-full">
                                <h2 className="text-[3.6rem] font-onest font-semibold text-hackathon-dark-1d mb-2">Challenges 3</h2>
                                <div className="w-full pb-8 border-b border-dashed border-[#D7D7D7] mb-[4rem]">
                                    <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-10 leading-relaxed">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation </p>
                                    <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-10 leading-relaxed">ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                                </div>
                                <div className="w-full flex justify-start items-center gap-[1.8rem]">
                                    <input type="checkbox" name="completed" id="completed" className="w-12 h-12 accent-hackathon-primary" />
                                    <label htmlFor="completed" className="text-[2.4rem] font-dmsans font-normal text-hackathon-dark">I have completed this Challenge </label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="w-full h-auto">
                <h5 className="text-[3.2rem] font-onest font-semibold text-hackathon-dark mb-2">Upload File</h5>
                <div className={`w-full h-[28rem] border border-dashed  rounded-[1.7rem] ${isDragging ? "border-green-500" : "border-[#B8BBF5]"}`} ref={dropZoneRef}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}>
                    <div className="w-full h-full flex justify-center items-center pointer-events-none">
                        <input
                            type="file"
                            id="uploadFiles"
                            onChange={handleFileInputChange}
                            onClick={(event: any) => {
                                event.target.value = null;
                            }}
                            ref={uploadFiles}
                            className="hidden w-0 h-0"
                        />
                        <div className="w-[70%] h-auto">
                            <div className="w-full flex justify-between items-center gap-[3rem]">
                                <div className="w-[14rem] h-auto">
                                    <img src={require("../../../assets/images/upload-icon.png")} alt="Upload Icon" />
                                </div>
                                <div className="flex-1">
                                    <p className="text-[3.4rem] font-onest font-medium text-hackathon-dark-1d mb-6">Drag and Drop your files here <span className="text-hackathon-gray-66">Or </span><label htmlFor="uploadFiles" className="text-hackathon-primary underline pointer-events-auto">Browse Files</label></p>
                                    <p className="text-[2rem] font-onest font-normal text-hackathon-gray-66">Supported formats: PPT, Word Doc, PDF, PNG, JPG with Max Size 25 MB</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="w-full flex justify-center items-center my-[3%]">
            <button type="button" className="w-[30%] text-[2.2rem] font-dmsans font-medium bg-hackathon-dark-1d text-hackathon-white py-[2rem] leading-none rounded-full">Submit PoEs</button>
            </div>
        </div>
    )
}
export default Challenges;