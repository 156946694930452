import ModalWrapper from "../../global-layouts/modal-wrapper";
import { HackathonLogo, ModalCloseIcon } from "../../svg";

interface LoginProps {
    handleClose: () => void;
}

function Login({ handleClose }: LoginProps) {
    return (
        <ModalWrapper onClose={handleClose}>
            <div className="w-full h-full flex justify-center items-center pointer-events-none">
                <div className="w-[41%] h-auto bg-hackathon-white pt-[6rem] pb-[5rem] px-[3rem] rounded-[2.2rem] pointer-events-auto relative">
                    <button type="button" className="w-[3.2rem] h-auto absolute top-[3rem] right-[3rem]" onClick={handleClose}>
                        <ModalCloseIcon />
                    </button>
                    <div className="w-full h-auto text-center">
                        <div className="w-[19rem] h-auto mx-auto mb-[4.2rem]">
                        <HackathonLogo />
                        </div>
                        <h3 className="text-[8rem] font-onest font-extralight text-hackathon-dark leading-tight mb-4">Sign in</h3>
                        <p className="text-[1.8rem] font-dmsans font-normal text-hackathon-gray-66 mb-[4rem]">Lorem ipsum dolor sit amet, consectetur</p>
                        <div className="w-[76%] mx-auto mb-[5rem]">
                            <form className="w-full">
                           <div className="w-full h-auto mb-[3rem]">
                              <input type="text" name="enterCoupon" className="w-full text-[2rem] font-onest border border-hackathon-gray-66 text-hackathon-gray-66 leading-tight px-[3rem] py-[2rem] rounded-full" placeholder="Enter your coupon code" />
                              {/* <p className="text-[1.8rem] font-dmsans font-normal text-[#E5252A] text-left">You have entered an invalid coupon code. Try again !</p> */}
                           </div>
                           <button type="button" className="w-full text-[2rem] text-hackathon-white text-center bg-hackathon-dark border border-hackathon-dark rounded-full leading-tight py-[2rem]">Sign In</button>
                            </form>
                        </div>
                        <div className="w-[70%] mx-auto mb-[4rem]">
                           <p className="text-[1.8rem] font-dmsans font-normal text-center text-hackathon-gray-66">This site is protected by reCAPTCHA and the Google <br /> Privacy Policy and Terms of Service apply.</p>
                        </div>

                    </div>
                </div>
            </div>

        </ModalWrapper>
    )
}
export default Login;