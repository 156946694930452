function Amazed(){
    return(
        <div className="w-full h-screen py-[5%] amazed-pattern">
            <div className="w-[77.6%] mx-auto">
                <div className="w-full h-auto">
                    <div className="w-full h-auto flex justify-between items-start">
                        <div className="w-[40%] h-auto">
                            <div className="w-full h-auto">
                                <img src={require("../../../assets/images/people-amazed.png")} alt="We Leave People Amazed!" />
                            </div>
                        </div>
                        <div className="w-[60%] h-auto pt-[7rem]">
                            <div className="w-full h-auto">
                                <h2 className="text-[6rem] font-onest font-semibold text-hackathon-dark leading-tight mb-[1.3rem]">We Leave People Amazed!</h2>
                                <p className="text-[3.2rem] font-dmsans font-normal text-hackathon-gray-66 leading-tight">In December 2023!,  We hosted our inaugural community hackathon. Since then, We’ve expanded to</p>
                            </div>
                            <div className="w-full h-auto">
                                <div className="w-full h-auto">
                                    <div className="w-full flex justify-start items-center gap-[4.2rem] mt-[7%]">
                                        <div className="w-[11rem] h-auto">
                                            <img src={require("../../../assets/images/ninjas.png")} alt="Ninjas" />
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="text-[8rem] font-onest font-medium text-hackathon-dark leading-tight">10,00,000+</h3>
                                            <p className="text-[3rem] font-onest font-light text-hackathon-gray-66 leading-tight">Code Ninjas</p>
                                        </div>
                                    </div>
                                    <div className="w-full flex justify-start items-center gap-[4.2rem] mt-[7%]">
                                        <div className="w-[11rem] h-auto">
                                            <img src={require("../../../assets/images/projects.png")} alt="Projects" />
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="text-[8rem] font-onest font-medium text-hackathon-dark leading-tight">10,000+</h3>
                                            <p className="text-[3rem] font-onest font-light text-hackathon-gray-66 leading-tight">Projects</p>
                                        </div>
                                    </div>
                                    <div className="w-full flex justify-start items-center gap-[4.2rem] mt-[7%]">
                                        <div className="w-[11rem] h-auto">
                                            <img src={require("../../../assets/images/hackathons.png")} alt="Hackathon" />
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="text-[8rem] font-onest font-medium text-hackathon-dark leading-tight">100+</h3>
                                            <p className="text-[3rem] font-onest font-light text-hackathon-gray-66 leading-tight">Hackathons</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Amazed;