import { CalendarIcon, LocationIcon } from "../../svg";

function HackathonInfo(){
    return(
        <div className="w-full flex justify-start items-center gap-[5.5rem]">
        <div className="w-auto flex justify-start items-center gap-[0.8rem] relative seperator">
            <div className="w-auto h-[2.5rem]">
                <CalendarIcon />
            </div>
            <p className="text-[1.8rem] font-onest font-medium text-hackathon-dark leading-none">06/01/2024 - 06/10/2024</p>
        </div>
        <div className="w-auto flex justify-start items-center gap-[0.8rem] relative seperator">
            <div className="w-auto h-[2.5rem]">
              <LocationIcon />
            </div>
            <p className="text-[1.8rem] font-onest font-medium text-hackathon-dark leading-none">United Kingdom</p>
        </div>
        <div className="w-auto flex justify-start items-center gap-[0.8rem]">
            <div className="w-[5.7rem] h-auto">
                  <img src={require("../../../assets/images/participants.png")} alt="Participants" />
            </div>
            <p className="text-[1.8rem] font-onest font-medium text-hackathon-dark leading-tight">+100</p>
        </div>
    </div>
    )
}
export default HackathonInfo;