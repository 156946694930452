import { Outlet, useLocation } from "react-router-dom";
import PageHeader from "../components/global-layouts/page-header";
import HackathonList from "../components/section/hackathon-list";

function Hackathon() {
  const { pathname } = useLocation();

  const checkLocation = () => {
    if (!pathname.includes("/hackathon/")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="w-full h-auto">
      {checkLocation() ?
        <>
          <PageHeader pageTitle="Hackathon" hackathonCount={22} />
          <HackathonList />
        </> :
        <Outlet />}
    </div>

  )
}
export default Hackathon;